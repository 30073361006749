import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Internet_Access/DDNS_Provider/NavButtons';
import ForumBox from 'components/Internet_Access/DDNS_Provider/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "DDNS Provider - DDNSS",
  "path": "/Internet_Access/DDNS_Provider/DDNSS/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "Use a Third Party DDNS Provider with your INSTAR IP Camera",
  "image": "./IA_SearchThumb_ThirdPartyDDNS_ddnss.png",
  "social": "/images/Search/IA_SearchThumb_ThirdPartyDDNS_ddnss.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_IA-DDNS_white.webp",
  "chapter": "Remote Access"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='DDNS Provider - DDNSS' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use a Third Party DDNS Provider with your INSTAR IP Camera' image='/images/Search/IA_SearchThumb_ThirdPartyDDNS_ddnss.png' twitter='/images/Search/IA_SearchThumb_ThirdPartyDDNS_ddnss.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Fernzugriff/DDNS_Anbieter/DDNSS/' locationFR='/fr/Internet_Access/DDNS_Provider/DDNSS/' crumbLabel="DDNSS" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "ddns-provider",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#ddns-provider",
        "aria-label": "ddns provider permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`DDNS Provider`}</h1>
    <h2 {...{
      "id": "ddnss--managed-ddns-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ddnss--managed-ddns-service",
        "aria-label": "ddnss  managed ddns service permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`DDNSS :: Managed DDNS Service`}</h2>
    <p>{`How to use your DDNSS address with your INSTAR IP camera?`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bf68b08a34642de83d5285514fe98152/5a190/Ddnss.de_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "31.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABQklEQVQY04WLsU7CUABF+wdSVwKRRGkcjDESf8IP0MndyS9wMsaPwJJQKUqLDAZHmIQAg5jQxmIi2vfa8goUAmjb19I+gwyO3tzhnpt7KTQcGci0xkt9Yd9ZkLlHvJAE5H9ToftNsD2bTRFCY8tyXTtcYOK7ZIFD7BBsE88JsLOcBf4Kif/bBz5VVIaCZL4226BWg61W//nlTjKznQEvDwUZCRISZVOUzUKnX+pafyihe2VE0RmVTn+UK23nTZko3d5T8+Th/bj8eVjq0Rk1wgKaBRFWjbDq2rW6CjQL1jOAZlUqzoHYDaz2LGJPPXtuWpPLBjqrakePICXquwV9+xbuCXpK1A+Kxr64DJs8jHEgzgEqmgXRLDitGFcNdFHvn9cHO4KRyOkbOY3hYZKHiRzc4jUmrzF5mMxDhtdiHFi9fgCzNBPTIngYKQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bf68b08a34642de83d5285514fe98152/e4706/Ddnss.de_01.avif 230w", "/en/static/bf68b08a34642de83d5285514fe98152/d1af7/Ddnss.de_01.avif 460w", "/en/static/bf68b08a34642de83d5285514fe98152/c9875/Ddnss.de_01.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bf68b08a34642de83d5285514fe98152/a0b58/Ddnss.de_01.webp 230w", "/en/static/bf68b08a34642de83d5285514fe98152/bc10c/Ddnss.de_01.webp 460w", "/en/static/bf68b08a34642de83d5285514fe98152/d00b9/Ddnss.de_01.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bf68b08a34642de83d5285514fe98152/81c8e/Ddnss.de_01.png 230w", "/en/static/bf68b08a34642de83d5285514fe98152/08a84/Ddnss.de_01.png 460w", "/en/static/bf68b08a34642de83d5285514fe98152/5a190/Ddnss.de_01.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bf68b08a34642de83d5285514fe98152/5a190/Ddnss.de_01.png",
              "alt": "Third Party DDNS Provider DDNSS",
              "title": "Third Party DDNS Provider DDNSS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`To create a DDNSS hostname, you first have to log in and create a user account.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/806281ec8820e3b99417de059c972197/5a190/Ddnss.de_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "29.565217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABF0lEQVQY02XNMU+DQAAFYPa2q2japu0PoLBCFCkUS1tohyYuJg46sTiW8P8cHBExYXChhAaMkB6XkBMKZ0RNrH55w5veI1rNVr/X67S75BFJHp90ut3+YNBud5r/NBp/QyjKRJZlqcaxLE3Tuq67rvv4ybYs66vYtu04zvMhYqZp6mIhK8opz5/x5wzDGIaBMS7LEteqX/Ah4kqULiVpJQhLlp1x3JCmTdNECL3WwihM0xRCmNYghDvwXVIAiLuLybU4uhmPbyVxxbLUz3McxwCAoiiyLANgt9/v8zyPk+T+JSuKAqL39OmB0OZzdapo0+lyro4EgRpS6/UaY+x5XhhG9cqb7/t5niOE/M0m2cGqqoJtEG2DD0Za11IrygiUAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/806281ec8820e3b99417de059c972197/e4706/Ddnss.de_02.avif 230w", "/en/static/806281ec8820e3b99417de059c972197/d1af7/Ddnss.de_02.avif 460w", "/en/static/806281ec8820e3b99417de059c972197/c9875/Ddnss.de_02.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/806281ec8820e3b99417de059c972197/a0b58/Ddnss.de_02.webp 230w", "/en/static/806281ec8820e3b99417de059c972197/bc10c/Ddnss.de_02.webp 460w", "/en/static/806281ec8820e3b99417de059c972197/d00b9/Ddnss.de_02.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/806281ec8820e3b99417de059c972197/81c8e/Ddnss.de_02.png 230w", "/en/static/806281ec8820e3b99417de059c972197/08a84/Ddnss.de_02.png 460w", "/en/static/806281ec8820e3b99417de059c972197/5a190/Ddnss.de_02.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/806281ec8820e3b99417de059c972197/5a190/Ddnss.de_02.png",
              "alt": "Third Party DDNS Provider DDNSS",
              "title": "Third Party DDNS Provider DDNSS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Once you are registered, log in and go to Domain Verwaltung...`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bafd0dee74ad58dc81f21ab2be8e9ba2/5a190/Ddnss.de_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "35.21739130434782%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABb0lEQVQY01XHy0oCUQDG8XmR1IJQSrHrwpnMMZwQQrqQG3euo00ZQiEta99TRNA6eoGWkS0ik8xxGCxn5lxm5pzOmZwThYH9+S2+T4pEoolEIh6PR2OxycmppaXlgqbJmYwyauXX3/mfVCqVisVioaCpqppOp+v1OsbYsW3HtiEACEIXQQRHIADjpOJP6/l8bnUlM5+aOW2cDIchdF3kugBh5GLdIgOIIUYAIcoY5ZwwRhijQSBVtis7G7u7m5X8VjW5utU4vxBh6L2+4k4HdzrUMKw30+sZtGe43a6v69QwmGmyfp++vEjV/aty7aa8d71wfB87eqhd6kKIz1YLPT56T0+h3g3aLfL8zNptoeuj0esJ0wyaTUlVtaySy2bX5paV6PRsrX4ihGCUfvl+QAh0sPM+gB8fnuNw3+eEBL7PPY97XkCpJMsZWZEXFxdSydloZKJ2eMA5H1gWgKBvg7NbeNdGxEc2hDYANgDOmG8eSRmpDTdflgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bafd0dee74ad58dc81f21ab2be8e9ba2/e4706/Ddnss.de_03.avif 230w", "/en/static/bafd0dee74ad58dc81f21ab2be8e9ba2/d1af7/Ddnss.de_03.avif 460w", "/en/static/bafd0dee74ad58dc81f21ab2be8e9ba2/c9875/Ddnss.de_03.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bafd0dee74ad58dc81f21ab2be8e9ba2/a0b58/Ddnss.de_03.webp 230w", "/en/static/bafd0dee74ad58dc81f21ab2be8e9ba2/bc10c/Ddnss.de_03.webp 460w", "/en/static/bafd0dee74ad58dc81f21ab2be8e9ba2/d00b9/Ddnss.de_03.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bafd0dee74ad58dc81f21ab2be8e9ba2/81c8e/Ddnss.de_03.png 230w", "/en/static/bafd0dee74ad58dc81f21ab2be8e9ba2/08a84/Ddnss.de_03.png 460w", "/en/static/bafd0dee74ad58dc81f21ab2be8e9ba2/5a190/Ddnss.de_03.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bafd0dee74ad58dc81f21ab2be8e9ba2/5a190/Ddnss.de_03.png",
              "alt": "Third Party DDNS Provider DDNSS",
              "title": "Third Party DDNS Provider DDNSS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`...and choose Neu Erstellen to create a new hostname. Type in a host for your camera and choose a domain from the drop down menu.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5eafebe6e92a1e311ff118db1fa2fd84/5a190/Ddnss.de_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABXklEQVQY03XQzWrCQBAH8DyDV7Fl600vCZ6sJCBJ1FRb9Va81ItKFDzoQ+jzeREPSsyHGzcWS6I1q8lum1goFPvjz5xmmGGYRCIBAEin08lkMpW6uwcgk8lwLAfAA4hc621M9akql2RRlERRLBQeWZZVVdXQ9XVM0zTDMMx/MM+1l3q9rigVnucFQcjlcqPRKAxDjLEfw7HLLUytWivJsiRJxWKRF3iO48bjMcZ4t3NsGzpbtLVttLU/9nvPdX8TYxTlSZJEWZYr5XI+n89ms8PhkFLqui5ynLVpQOTQGPkTQpjBYNDpdHq9nqqq7bd2s9mcTqc/rZeLu1gclsvAss66Hm421LajQEiPR0opo8Wu7zEMQ9d1hBAhJNqFsb9a+afTyfPeEfIPBxoEJAiI50XzlDL9fr/b7b7GWq1Wo9GYTCbXOynG59nsU9NC0yQQUgjJZkMgPM7nxLK+h78AS51EiLCLhukAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5eafebe6e92a1e311ff118db1fa2fd84/e4706/Ddnss.de_04.avif 230w", "/en/static/5eafebe6e92a1e311ff118db1fa2fd84/d1af7/Ddnss.de_04.avif 460w", "/en/static/5eafebe6e92a1e311ff118db1fa2fd84/c9875/Ddnss.de_04.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5eafebe6e92a1e311ff118db1fa2fd84/a0b58/Ddnss.de_04.webp 230w", "/en/static/5eafebe6e92a1e311ff118db1fa2fd84/bc10c/Ddnss.de_04.webp 460w", "/en/static/5eafebe6e92a1e311ff118db1fa2fd84/d00b9/Ddnss.de_04.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5eafebe6e92a1e311ff118db1fa2fd84/81c8e/Ddnss.de_04.png 230w", "/en/static/5eafebe6e92a1e311ff118db1fa2fd84/08a84/Ddnss.de_04.png 460w", "/en/static/5eafebe6e92a1e311ff118db1fa2fd84/5a190/Ddnss.de_04.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5eafebe6e92a1e311ff118db1fa2fd84/5a190/Ddnss.de_04.png",
              "alt": "Third Party DDNS Provider DDNSS",
              "title": "Third Party DDNS Provider DDNSS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can leave everything as is - we will configure our router in the next step to take care of updating this IP address.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5b78b1475fe9aac7e7c932531a20c428/5a190/Ddnss.de_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "28.260869565217394%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAz0lEQVQY043Py26DMBRFUX6ISYVEhfD/z5kisJAFuTZ+cYvBsROclip9jNo1PNIenIwQUtf166mqKkJIWZZ5nhdF8fKXbJ5nzrlSSkqplDLGWGv3fU//kHVdRylljPV9TykdxxERrbVaa+fc/mnbYowppeP0HTdNwxgbhkE9ee/jKYTgQ/Ax+hDuP5pfcdu2lFIAEEIAwDRNxph1XZ1zN8S0LAnxivi2rsuybE/HcWQAIKXUWgshtNZf5znnH8vlggDIuRVCKgUAzrnr6T1+AAlmKFGLU2YWAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5b78b1475fe9aac7e7c932531a20c428/e4706/Ddnss.de_06.avif 230w", "/en/static/5b78b1475fe9aac7e7c932531a20c428/d1af7/Ddnss.de_06.avif 460w", "/en/static/5b78b1475fe9aac7e7c932531a20c428/c9875/Ddnss.de_06.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5b78b1475fe9aac7e7c932531a20c428/a0b58/Ddnss.de_06.webp 230w", "/en/static/5b78b1475fe9aac7e7c932531a20c428/bc10c/Ddnss.de_06.webp 460w", "/en/static/5b78b1475fe9aac7e7c932531a20c428/d00b9/Ddnss.de_06.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5b78b1475fe9aac7e7c932531a20c428/81c8e/Ddnss.de_06.png 230w", "/en/static/5b78b1475fe9aac7e7c932531a20c428/08a84/Ddnss.de_06.png 460w", "/en/static/5b78b1475fe9aac7e7c932531a20c428/5a190/Ddnss.de_06.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5b78b1475fe9aac7e7c932531a20c428/5a190/Ddnss.de_06.png",
              "alt": "Third Party DDNS Provider DDNSS",
              "title": "Third Party DDNS Provider DDNSS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The dashboard already tells us everything we need to know to set up our router in the next step. The complete update URL is:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`http://www.ddnss.de/upd.php?user=USERNAME&pwd=PASSWORT&host=HOSTNAME`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/31e2f627d98b024a09853831bf0d2d74/5a190/Ddnss.de_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.52173913043479%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB+UlEQVQoz12OT2vUQBjG8wH9OHrR2q9gpVDwIHgRtsWjWNCLFz0Irey63d1212yymWT+vzOZmZ3MJOlG0iKI8OPhxwMP75ucTb59P3lyevL66WR1fDE7/jA/Op+9+bx4+2Vxdjk//fTr7HL+6uP8+WT68mL2YjI9Oh/l2fuf776uku7eus4ehnYYvDTAtdCNk3snnIW9A28RAKnrdUVKgB1nlYKCcyRlCTL5nWXpNmv2eu/VdFP8uNnOMzZLySwl13fVYsduMrIck20wpFSnVKVUb4hGEhIhaVEU1kAIElhBUMaqHCgSuGDVTlKkOJK0EGQHtACGFBsbq0gXVbLI8NUdvsW1NBw4KlFOSaGAmnp83Bk5ipGjP2BqqRUfjzWQpFma5nkXbYx8bwXFBS5zXOY1ME5LwbCpZa24BqaBKkm9U33r2mBjkMnibl0R2rc+BNpFrSSrFWu89lZhhHCJJGO4QpJjEAQ4cUZ20TyMRSJga13VdTzGog1a0BIE7lsb9jWpSkYwJ0Qr2bfukS7aNvwdD8NqOKyGw+K+v41Ba6C25l003imtIQYfgw/eddH0rf0nXRtZMhxmj/T9PHjtnQ6+Dt4Eb2JjmwcJjdWKc4oVcM6I1gIEt7ZKhsPVI4f76xjTNuYxZv/Rtrk1ayWXY8LSuU2t101z+wdNwFUWgeI4qAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/31e2f627d98b024a09853831bf0d2d74/e4706/Ddnss.de_07.avif 230w", "/en/static/31e2f627d98b024a09853831bf0d2d74/d1af7/Ddnss.de_07.avif 460w", "/en/static/31e2f627d98b024a09853831bf0d2d74/c9875/Ddnss.de_07.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/31e2f627d98b024a09853831bf0d2d74/a0b58/Ddnss.de_07.webp 230w", "/en/static/31e2f627d98b024a09853831bf0d2d74/bc10c/Ddnss.de_07.webp 460w", "/en/static/31e2f627d98b024a09853831bf0d2d74/d00b9/Ddnss.de_07.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/31e2f627d98b024a09853831bf0d2d74/81c8e/Ddnss.de_07.png 230w", "/en/static/31e2f627d98b024a09853831bf0d2d74/08a84/Ddnss.de_07.png 460w", "/en/static/31e2f627d98b024a09853831bf0d2d74/5a190/Ddnss.de_07.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/31e2f627d98b024a09853831bf0d2d74/5a190/Ddnss.de_07.png",
              "alt": "Third Party DDNS Provider DDNSS",
              "title": "Third Party DDNS Provider DDNSS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`To use your AVM Fritzbox router to keep your new DDNSS address up-to-date, go to Internet/Freigaben, choose Benutzerdefiniert (Custom) the dropdown menu and type in your account information according to the instruction above. Your router will now update your DDNSS address every time your internet service provider assigns you a new WAN IP address.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2d73f89524c24e6af029acd6a4c4a5cc/5a190/Ddnss.de_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACHUlEQVQoz02QzU4TURiGZ0UC94CwJiHUkmC1a1as8SJcaCKGQNiQcBWitbpA4TI00cQNIVGwpdMz099pR2bm/M/M+TWdsYRnec73fu/3vs7P5eWz9fVXa2t7q6uHtdr+1tbBk603lcpRvX5Yq+1VNg6fPnu9sXFUr+8/rrxcWXnxaHl3cfH50tLuwoLTbjTajcbvk5Nfx8ed09POh8bt6dvhp4/j8/Ph2dmw2RxfXAybzeGXz+P37/rb26BaBdWqt7nZ29lxpkEQUhoJEQl5l6YhpSHjUCkoJNYKa42kREohrXGeg+8/+l+/wctLfHXFBwPn72gcBqEU0hbABEXhnZJCCmGNMUrPXo2x1iohr1tuG/S8wdgbBoRnThwnGFNTfFtrEcJRHDPGMMaU0jzPlVJaz1YopYDrtVuu23ZvrttJAp1JMI0jWGw3hTMEnj+dhlEUQYSklBiTNMtKsdsBAPQ80PO9PueZE93FjLIszTKeZjzljFPK0jRVc0xBKQ6CSRBMJpNplufWWgclCUOYIcJiSKKYIEwZp4TKXMwil5kLlFK+13Nd0O16o1FAKXOSBEKEE4iyLDfW6jlKayllmfZe3Gp1bltd0PX/3NxCiBxKqVL6fyvG2HlzZQsPxVLKruuDru/7g35/RAibiaWUQggpZXGnEkKUUWf+Sj1w1oRQM6fIjBBGBCFMMDHGcM4JIXmel2Kt9f30wytK/gHEnXKV6yd1LAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2d73f89524c24e6af029acd6a4c4a5cc/e4706/Ddnss.de_08.avif 230w", "/en/static/2d73f89524c24e6af029acd6a4c4a5cc/d1af7/Ddnss.de_08.avif 460w", "/en/static/2d73f89524c24e6af029acd6a4c4a5cc/c9875/Ddnss.de_08.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2d73f89524c24e6af029acd6a4c4a5cc/a0b58/Ddnss.de_08.webp 230w", "/en/static/2d73f89524c24e6af029acd6a4c4a5cc/bc10c/Ddnss.de_08.webp 460w", "/en/static/2d73f89524c24e6af029acd6a4c4a5cc/d00b9/Ddnss.de_08.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2d73f89524c24e6af029acd6a4c4a5cc/81c8e/Ddnss.de_08.png 230w", "/en/static/2d73f89524c24e6af029acd6a4c4a5cc/08a84/Ddnss.de_08.png 460w", "/en/static/2d73f89524c24e6af029acd6a4c4a5cc/5a190/Ddnss.de_08.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2d73f89524c24e6af029acd6a4c4a5cc/5a190/Ddnss.de_08.png",
              "alt": "Third Party DDNS Provider DDNSS",
              "title": "Third Party DDNS Provider DDNSS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`To use DD-WRT router to keep your new DDNSS address up-to-date, go to Setup/DDNS, choose Custom from the dropdown menu and type in your account information. The HASH key used here in the Update URL can be found on the dashboard of the DDNSS website. Alternatively, you can also use the password and username. Your router will now update your DDNS address every time your internet service provider assigns you a new WAN IP address.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      